/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function ComingSoon2(props) {
  const { Logo, Gif, overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="1440px"
      height="760px"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="30px 0px 30px 0px"
      backgroundColor="rgba(253,252,247,1)"
      {...getOverrideProps(overrides, "ComingSoon2")}
      {...rest}
    >
      <Flex
        gap="64px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="16px 32px 16px 32px"
        {...getOverrideProps(overrides, "Nav Bar")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="217px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="8px 8px 8px 8px"
          {...getOverrideProps(overrides, "logo")}
        >
          <Image
            width="103px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={Logo}
            {...getOverrideProps(overrides, "WhisperiFy (26) 2")}
          ></Image>
          <Flex
            gap="0"
            direction="row"
            width="112px"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 8px 8px 8px"
            {...getOverrideProps(overrides, "Name")}
          >
            <Text
              fontFamily="Roboto"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,54,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="WhisperiFY"
              {...getOverrideProps(overrides, "WhisperiFY")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="633.94px"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1000003693")}
        >
          <Flex
            gap="40px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Menu list")}
          >
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "menu 3")}
            >
              <Text
                fontFamily="Roboto"
                fontSize="20px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Support"
                {...getOverrideProps(overrides, "Support")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "fa-solid:share-alt")}
          >
            <Icon
              width="21.88px"
              height="25px"
              viewBox={{ minX: 0, minY: 0, width: 21.875, height: 25 }}
              paths={[
                {
                  d: "M17.1875 15.625C16.0836 15.625 15.069 16.0068 14.2681 16.6453L9.26387 13.5176C9.41206 12.8473 9.41206 12.1527 9.26387 11.4823L14.2681 8.35469C15.069 8.99321 16.0836 9.375 17.1875 9.375C19.7763 9.375 21.875 7.27632 21.875 4.6875C21.875 2.09868 19.7763 0 17.1875 0C14.5987 0 12.5 2.09868 12.5 4.6875C12.5 5.03701 12.5386 5.37744 12.6111 5.70513L7.60693 8.83276C6.80601 8.19429 5.79141 7.8125 4.6875 7.8125C2.09868 7.8125 0 9.91118 0 12.5C0 15.0888 2.09868 17.1875 4.6875 17.1875C5.79141 17.1875 6.80601 16.8057 7.60693 16.1672L12.6111 19.2949C12.5372 19.629 12.4999 19.9703 12.5 20.3125C12.5 22.9013 14.5987 25 17.1875 25C19.7763 25 21.875 22.9013 21.875 20.3125C21.875 17.7237 19.7763 15.625 17.1875 15.625Z",
                  fill: "rgba(0,0,54,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 160px 0px 160px"
        {...getOverrideProps(overrides, "Frame 1000003459")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1000003460")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="8px 16px 8px 16px"
            {...getOverrideProps(overrides, "Frame 456")}
          >
            <Text
              fontFamily="Roboto"
              fontSize="36px"
              fontWeight="500"
              color="rgba(0,0,54,1)"
              lineHeight="54px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Submission secured! "
              {...getOverrideProps(overrides, "Submission secured!")}
            ></Text>
            <Text
              fontFamily="Roboto"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,54,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Thanks for your interest! Our team will contact you soon with next steps."
              {...getOverrideProps(
                overrides,
                "Thanks for your interest! Our team will contact you soon with next steps."
              )}
            ></Text>
            <Text
              fontFamily="Roboto"
              fontSize="16px"
              fontWeight="600"
              color="rgba(0,0,54,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="WhisperiFY beta - Simple. Affordable. AI Audio transcriber"
              {...getOverrideProps(
                overrides,
                "WhisperiFY beta - Simple. Affordable. AI Audio transcriber"
              )}
            ></Text>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1000003696")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.30000001192092896)"
                borderRadius="8px"
                padding="8px 24px 8px 24px"
                backgroundColor="rgba(0,0,54,1)"
                {...getOverrideProps(overrides, "Frame 461")}
              >
                <Text
                  fontFamily="Roboto"
                  fontSize="20px"
                  fontWeight="500"
                  color="rgba(253,252,247,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Spread the word to your circle!"
                  {...getOverrideProps(
                    overrides,
                    "Spread the word to your circle!"
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                {...getOverrideProps(overrides, "Blog connection65381337")}
              >
                <Text
                  fontFamily="Roboto"
                  fontSize="20px"
                  fontWeight="500"
                  color="rgba(0,0,54,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Learn More"
                  {...getOverrideProps(overrides, "Learn More")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1000003697")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                {...getOverrideProps(overrides, "Blog connection6763995")}
              ></Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="148px 0px 148px 0px"
          {...getOverrideProps(overrides, "Frame 1000003458")}
        >
          <Image
            width="500px"
            height="452px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={Gif}
            {...getOverrideProps(overrides, "LoadingMascot")}
          ></Image>
          <View
            width="unset"
            height="78px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1000003499")}
          ></View>
          <View
            width="unset"
            height="78px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1000003500")}
          ></View>
        </Flex>
      </Flex>
    </Flex>
  );
}
