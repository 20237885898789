import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import ComingSoon1 from "./ui-components/ComingSoon1";
import ComingSoon2 from "./ui-components/ComingSoon2";
import comingsoonGif from "../src/assets/coming soon.gif";
import emailGif from "../src/assets/mail.gif";
import logo from "../src/assets/logo.png";

function App() {
  const [emailId, setEmailId] = useState("");
  const [isEmailInteracted, setIsEmailInteracted] = useState(false);
  const [emalErrorMessage, setEmailErrorMessage] = useState();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    console.log("clicked");

    const isFormValid = await handleFormValidation();
    console.log(isFormValid);
    if (isFormValid) {
      setEmailId("");
      try {
        const response = await fetch(
          "https://brigavcvyzistsj6jia4yrmap40xqepm.lambda-url.ap-south-1.on.aws/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: emailId }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        navigate("/success");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFormValidation = () => {
    return new Promise((resolve, reject) => {
      if (!isEmailInteracted) {
        resolve(false);
        return;
      }
      if (!emailId) {
        setEmailErrorMessage("Please enter your email address.");
        console.log("Please enter your email address.");

        resolve(false);
        return;
      }
      if (!emailId.includes(".") && !emailId.includes("@")) {
        setEmailErrorMessage(
          " Invalid email format.Please enter a valid email address."
        );

        console.log(
          "Invalid email format. Please enter a valid email address."
        );
        resolve(false);
        return;
      } else {
        setEmailErrorMessage("");
      }
      console.log("true");
      resolve(true);
      return;
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ComingSoon1
            width="100vx"
            height="100vh"
            Logo={logo}
            Gif={comingsoonGif}
            overrides={{
              Button: {
                onClick: handleSubmit,

                color: "white",
              },
              EmailErrorMessage: { children: emalErrorMessage },
              TextField: {
                value: emailId,
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                    e.preventDefault();
                  }
                },
                onChange: (e) => {
                  setEmailId(e.target.value);
                  setIsEmailInteracted(true);
                },
              },
            }}
          />
        }
      />
      <Route
        path="/success"
        element={
          <ComingSoon2
            width="100vx"
            height="100vh"
            Gif={emailGif}
            Logo={logo}
            overrides={{
              LoadingMascot: { backgroundImage: `url(${comingsoonGif})` },
            }}
          />
        }
      />
    </Routes>
  );
}

export default App;
