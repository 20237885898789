/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  Flex,
  Icon,
  Image,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function ComingSoon1(props) {
  const { Logo, Gif, overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="1440px"
      height="760px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="16px 0px 16px 0px"
      backgroundColor="rgba(253,252,247,1)"
      {...getOverrideProps(overrides, "ComingSoon1")}
      {...rest}
    >
      <Flex
        gap="64px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="16px 32px 16px 32px"
        {...getOverrideProps(overrides, "Nav Bar")}
      >
        <Flex
          gap="0"
          direction="row"
          width="217px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 8px 0px 8px"
          {...getOverrideProps(overrides, "logo")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="8px 8px 8px 8px"
            {...getOverrideProps(overrides, "Name")}
          >
            <Image
              width="103px"
              height="40px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="contain"
              src={Logo}
              {...getOverrideProps(overrides, "WhisperiFy (26) 1")}
            ></Image>
            <Text
              fontFamily="Roboto"
              fontSize="20px"
              fontWeight="400"
              color="rgba(0,0,54,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="WhisperiFY"
              {...getOverrideProps(overrides, "WhisperiFY")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 1000003692")}
        >
          <Flex
            gap="0"
            direction="row"
            width="649.67px"
            height="unset"
            justifyContent="flex-end"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 1000003691")}
          >
            <Flex
              gap="40px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-end"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Menu list")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                {...getOverrideProps(overrides, "menu 2")}
              >
                <Text
                  fontFamily="Roboto"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,54,1)"
                  lineHeight="30px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Support"
                  {...getOverrideProps(overrides, "Support")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "fa-solid:share-alt")}
          >
            <Icon
              width="21.88px"
              height="25px"
              viewBox={{ minX: 0, minY: 0, width: 21.875, height: 25 }}
              paths={[
                {
                  d: "M17.1875 15.625C16.0836 15.625 15.069 16.0068 14.2681 16.6453L9.26387 13.5176C9.41206 12.8473 9.41206 12.1527 9.26387 11.4823L14.2681 8.35469C15.069 8.99321 16.0836 9.375 17.1875 9.375C19.7763 9.375 21.875 7.27632 21.875 4.6875C21.875 2.09868 19.7763 0 17.1875 0C14.5987 0 12.5 2.09868 12.5 4.6875C12.5 5.03701 12.5386 5.37744 12.6111 5.70513L7.60693 8.83276C6.80601 8.19429 5.79141 7.8125 4.6875 7.8125C2.09868 7.8125 0 9.91118 0 12.5C0 15.0888 2.09868 17.1875 4.6875 17.1875C5.79141 17.1875 6.80601 16.8057 7.60693 16.1672L12.6111 19.2949C12.5372 19.629 12.4999 19.9703 12.5 20.3125C12.5 22.9013 14.5987 25 17.1875 25C19.7763 25 21.875 22.9013 21.875 20.3125C21.875 17.7237 19.7763 15.625 17.1875 15.625Z",
                  fill: "rgba(0,0,54,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="71px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 160px 8px 160px"
        {...getOverrideProps(overrides, "MainArea")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Hero")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="8px 16px 8px 16px"
            {...getOverrideProps(overrides, "Frame 455")}
          >
            <Text
              fontFamily="Roboto"
              fontSize="14px"
              fontWeight="500"
              color="rgba(0,0,54,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Experience AI without breaking your bank!"
              {...getOverrideProps(
                overrides,
                "Experience AI without breaking your bank!"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 16px 0px 16px"
            {...getOverrideProps(overrides, "Frame 456")}
          >
            <Text
              fontFamily="roboto"
              fontSize="36px"
              fontWeight="400"
              color="rgba(0,0,54,1)"
              lineHeight="54px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Your $1 &#x2028;AI Audio Transcription app."
              {...getOverrideProps(
                overrides,
                "Your $1 \u2028AI Audio Transcription app."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 16px 0px 16px"
            {...getOverrideProps(overrides, "Frame 462")}
          >
            <Text
              fontFamily="Roboto"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,54,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Get a whopping 40 hours of accurate transcription for only ONE dollar!"
              {...getOverrideProps(
                overrides,
                "Get a whopping 40 hours of accurate transcription for only ONE dollar!"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="8px 16px 8px 16px"
            {...getOverrideProps(overrides, "Frame 463")}
          >
            <Text
              fontFamily="Roboto"
              fontSize="14px"
              fontWeight="400"
              color="rgba(0,0,54,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Join the WhisperiFY Beta today and experience the magic of seamlessly converting audio to text. "
              {...getOverrideProps(
                overrides,
                "Join the WhisperiFY Beta today and experience the magic of seamlessly converting audio to text."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 16px 0px 16px"
            {...getOverrideProps(overrides, "Frame 1000003491")}
          >
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="37px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 1000003724")}
            >
              <TextField
                width="300px"
                height="unset"
                placeholder="Provide your email to receive updates"
                padding="8px 0px 8px 0px"
                shrink="0"
                size="small"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                {...getOverrideProps(overrides, "TextField")}
              ></TextField>
              <Button
                width="unset"
                height="unset"
                borderRadius="8px"
                grow="1"
                shrink="1"
                basis="0"
                backgroundColor="rgba(0,0,54,1)"
                size="default"
                isDisabled={false}
                variation="default"
                children="Join the waiting list!"
                {...getOverrideProps(overrides, "Button")}
              ></Button>
            </Flex>
            <Text
              fontFamily="Roboto"
              fontSize="14px"
              fontWeight="400"
              color="rgba(149,4,4,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0px"
              width="353px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Error Message"
              {...getOverrideProps(overrides, "EmailErrorMessage")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="69px 0px 69px 0px"
          {...getOverrideProps(overrides, "Frame")}
        >
          <Image
            width="500px"
            height="343px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={Gif}
            {...getOverrideProps(overrides, "LoadingMascot")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
